import React, { useEffect, useRef, useState } from 'react';
import { loadScene } from './Service';

const Scene = () => {
  const canvasRef = useRef();
  const [canvasSize, setCanvasSize] = useState({ width: '100%', height: '100vh' });
  const sceneUrl = process.env.REACT_APP_SCENE;
  
  useEffect(() => {
    const handleResize = () => {
      setCanvasSize({
        width: `${window.innerWidth}px`,
        height: `${window.innerHeight}px`,
      });
    };

    loadScene(canvasRef.current, sceneUrl);

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [sceneUrl]);

  return (
    <canvas 
      ref={canvasRef} 
      style={{ 
        width: canvasSize.width, 
        height: canvasSize.height,
        maxWidth: '100%',
        maxHeight: '100vh',
        objectFit: 'contain'
      }} 
    />
  );
};

const App = () => {
  return (
    <div className="App">
      <div className="w-full h-screen">
        <Scene />
      </div>
    </div>
  );
};

export default App;
